<template>
  <div class="top-sales-wrapper">
    <div class="chart-title">{{ $t('Total Sales (This Year)') }}</div>
    <div class="chart-wrapper">
      <LineChart :chartData="chartData" :options="chartOptions" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
dayjs.extend(localeData);
import 'dayjs/locale/zh-hk';

import LineChart from '@/components/chart/LineChart.vue';
import Common from '@/lib/common';
import Report from '@/lib/report';

export default {
  name: 'TotalSales',
  components: {
    LineChart,
  },
  async mounted(){
    let totalSales = await this.loadTotalSales();
    totalSales = Object.values(totalSales);

    //If current langcode is chinese
    if(this.langcode === 'zh'){
      dayjs.locale('zh-hk');
    }
    const months = dayjs.months();
    const chartData = {
      labels: months,
      datasets: [
        {
          label: this.$t('Total Sales'),
          data: totalSales,
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }
      ],
    };
    this.chartData = chartData;
  },
  methods:{
    async loadTotalSales(){
      try{
        const loginInfo = Common.getLoginInfo();
        const totalSales = await Report.loadTotalSales(this.apiUrl, loginInfo);
        return totalSales;
      }catch(err){
        console.log(err);
      }
    },
  },
  data(){
    return {
      chartData: null,
      year: dayjs().year(),
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      }
    }
  },
  watch: {
    'langcode': function(){
      //Watch the langcode change, and then update the chart data.
      if(this.langcode === 'zh'){
        dayjs.locale('zh-hk');
      }else{
        dayjs.locale('en');
      }
      const months = dayjs.months();
      this.chartData.labels = months;
      this.chartData.datasets[0].label = this.$t('Total Sales');
      const newCharData = Object.assign({}, this.chartData);
      this.chartData = newCharData;
    }
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style scoped lang="scss">
.top-sales-wrapper{
  margin-top: 50px;
}
</style>